body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#main {
overflow: hidden;
  background-size: cover;
  
  background-position: center;
  background-blend-mode: color-dodge;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  min-height: 1000px;
  display: flex;
  align-items: start;
  position: relative;


}

#overlay {
  background: url('bg.png');
  background-size: cover;
  position: absolute;
  background-position: center;
  
  
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  min-height: 1000px;
  display: flex;
  align-items: start;
  &.dark {
    background: url('bg-dark.png');
    background-size: cover;
    position: absolute;
    background-position: center;
    
    
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    min-height: 1000px;
    display: flex;
    align-items: start;
  }
  &.julio {
    background: url('./julio.jpeg');
    background-size: cover;
    position: absolute;
    background-position: center;
    
    
    background-repeat: no-repeat;

  }
  
}

.countdown {
  font-family: 'mlb-mariners-regular', sans-serif;
  color: #CE1141;
  -webkit-text-stroke: 3px #13274F;
  text-stroke: 3px #13274F;
  text-shadow: 0px 5px 0px  #fff;
  box-sizing: border-box;
  position: absolute;
  user-select: none; 
  top:2vh;
flex: 1;
width: 100%;

 
  
padding-right: 3.2vw;

text-align: center;
cursor: default;

.date {
  font-size: 150px;
  margin-top: 0px;
  margin-bottom: 0px;
  &.small {
    font-size: 90px;
    margin-top: -40px;
    margin-bottom: 40px;
    -webkit-text-stroke: 2.5px #13274F;
    
    color: rgb(221, 221, 221);
    text-shadow: none;
  }
 
}
.stats {

  color: white !important;
  -webkit-text-stroke: .5px #13274F;
  
  text-shadow: 0px 0px 0px  #fff;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 43px;
  font-weight: normal;
  
  width: 100%;
  text-align: center;
  @media only screen and (max-height: 575.98px)  {
   
    }
  margin: 0px;

}
.stats.season {
  font-size: 23px;
}
}
@font-face{font-family:"mlb-mariners-regular";src:url("./font.ttf") format("woff"),url("./font.ttf") format("opentype"),url("./font.ttf") format("truetype");}

@media screen and (max-device-width: 767px) and (orientation: portrait) {
  
 #main {
  .countdown {
    top: 20vh
  }
  .date {
    font-size: 188px;
  }
  .stats {
    font-size: 70px !important;
  }

  .trophy-case {
 
    img {
      display: inline;
      width: 48px;
      margin-top: -15px;
    }
    font-size: 65px;
    background-color: red;
    font-family: "Roboto Condensed", sans-serif;
    -webkit-text-stroke: 0px #13274F;
    text-shadow: none;
    color: #eaac00;
    text-align: center;
    width: 100%;
    position: absolute;
    
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }
 }
  }

.trophy-case {
 
  img {
    display: inline;
    width: 30px;
    margin-top: -25px;
  }
  font-size: 40px;
  background-color: red;
  font-family: "Roboto Condensed", sans-serif;
  -webkit-text-stroke: 0px #13274F;
  text-shadow: none;
  color: #eaac00;
  text-align: center;
  width: 100%;
  position: absolute;
  
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

$particles: 50;
$width: 500;
$height: 500;

// Create the explosion...
$box-shadow: ();
$box-shadow2: ();
@for $i from 0 through $particles {
  $box-shadow: $box-shadow,
               random($width)-$width / 2 + px
               random($height)-$height / 1.2 + px
               hsl(random(360), 100, 50);
  $box-shadow2: $box-shadow2, 0 0 #fff
}
@mixin keyframes ($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }

    @-moz-keyframes #{$animationName} {
        @content;
    }

    @-o-keyframes #{$animationName} {
        @content;
    }

    @-ms-keyframes #{$animationName} {
        @content;
    }

    @keyframes #{$animationName} {
        @content;
    }
}

@mixin animation-delay ($settings) {
    -moz-animation-delay: $settings;
    -webkit-animation-delay: $settings;
    -o-animation-delay: $settings;
    -ms-animation-delay: $settings;
    animation-delay: $settings;
}

@mixin animation-duration ($settings) {
    -moz-animation-duration: $settings;
    -webkit-animation-duration: $settings;
    -o-animation-duration: $settings;
    -ms-animation-duration: $settings;
    animation-duration: $settings;
}

@mixin animation ($settings) {
    -moz-animation: $settings;
    -webkit-animation: $settings;
    -o-animation: $settings;
    -ms-animation: $settings;
    animation: $settings;
}

@mixin transform ($settings) {
    transform: $settings;
    -moz-transform: $settings;
    -webkit-transform: $settings;
    -o-transform: $settings;
    -ms-transform: $settings;
}

body {
  margin:0;
  padding:0;
  background: #000;
  overflow: hidden;
}

.pyro > .before, .pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: $box-shadow2;
  opacity: .7;
  @include animation((3s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards));
}
    
.pyro > .after {
  @include animation-delay((1.25s, 1.25s, 1.25s));
  @include animation-duration((1.25s, 1.25s, 6.25s));
}
        
@include keyframes(bang) {
  to {
    box-shadow:$box-shadow;
  }
}
    
@include keyframes(gravity)  {
  to {
    @include transform(translateY(200px));
    opacity: 0;
  }
}
    
@include keyframes(position) {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {  
    margin-top: 20%;
    margin-left: 70%
  }
  60%, 79.9% {  
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {  
    margin-top: 30%;
    margin-left: 80%;
  }
}
